import React, { useEffect, useState, useContext } from "react"
import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import BgImage from "../../static/img/salvex-careers-banner.jpg"
import GroupImage from "../../static/img/group.jpg"
import { navigate } from "gatsby"
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client"
const CareersPage = () => {
  const [positions, setPositions] = useState([])

  const client = new ApolloClient({
    link: new HttpLink({
      uri: "https://api.monday.com/v2",
      fetch,
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEzNzM2ODE4NywidWlkIjoxMzk0NzM2NywiaWFkIjoiMjAyMS0xMi0xNVQxNzowNDo1NC4yNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NjE5MDU1NiwicmduIjoidXNlMSJ9.OYbS9wZZWFZ6GdiimOmVeQOk0MJCDgHQVtlUxajJOYc",
      },
    }),
    cache: new InMemoryCache(),
  })
  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            boards(ids: 2042291505) {
              items_page {
                items {
                  name
                  id
                  column_values {
                    text
                  }
                }
              }
              columns {
                id
                title
                type
                settings_str
              }
            }
          }
        `,
      })
      .then((result) => {
        const positions = result.data.boards.length
                  ? result.data.boards[0].items_page
                  : { items: [] }

                  setPositions(
                    positions.items.filter((item) =>
                      item.column_values.find(
                          (item) => item.text === "Open"
                      )
                    )
                  )
      })
  }, [])

  const onClickPosition = (positionId) => {
    navigate(`/position-details?id=${positionId}`)
  }

  return (
    <Layout>
      <Parallax bgImage={BgImage} className="text-center parallax-secondary">
        <div className="container-fluid">
          <div className="row">
            <div className="col"></div>
            <div className="col-md-7">
              <h1 className="text-white wow fadeInDown">CAREERS</h1>
              <h3 className="text-white wow fadeInUp subtitle">
                Welcome to Salvex!
              </h3>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </Parallax>
      <div className="container-fluid pb-4">
        <div className="container mb-4">
          <div className="row">
            <div className="col pt-4">
              <h2 className="text-primary mt-4 text-center">About Us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col-md-12">
              <p className="mt-4 mb-4">
                Salvex provides a global, ecommerce platform that serves as a
                marketplace for the resell of commercial surplus and secondary
                assets for B2B clients. This type of sustainable procurement
                program allows Salvex to provide solutions for our clients
                within the circular economy.
              </p>
              <p className="mt-4 mb-4">
                Prioritizing social responsibility has always been within our
                mission and our global team has successfully offset over 250,000
                tons of CO2. Additionally, the resell of assets has provided a
                stream for funding of environmental causes that our Talent
                Ambassadors support.
              </p>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-primary">
        <div className="container mb-4" style={{ position: "relative" }}>
          <div
            className="col-md-5 bg-warning"
            style={{
              position: "absolute",
              height: "2px",
              top: "5rem",
              left: "-5rem",
            }}
          />
          <div className="container mb-4">
            <div className="row">
              <div className="col-md-7 col-founder bg-primary">
                <h2 className="text-white font-weight-normal pt-4 mb-4">
                  Overview
                </h2>
                <p className="mt-4 text-white">
                  Making a lasting impact on the well-being of people and the
                  planet now and for future generations is at the heart of what
                  we do at Salvex. We realized long ago that the "lifecycle of
                  things" has an enormous impact on life - people, natural
                  resources, ecosystems...basically all things we care about and
                  depend upon. As a community of socially responsible thinkers,
                  we have accepted the infinite challenge to make the world a
                  better place through sustainable asset procurement. Are you
                  ready to join the Salvex mission?
                </p>
              </div>
              <div className="col-md-5 text-center pb-0 pt-0">
                <img
                  src={GroupImage}
                  alt="Group Conference"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-4">
        <div className="container mb-4">
          <div className="row">
            <div className="col pt-4">
              <h2 className="text-primary mt-4 text-center">
                Consultant ROLES
              </h2>
              <p className="mt-4">
                Operating globally creates many opportunities to interact with
                incredibly talented and diverse people to share in a common
                cause: Make a world a better place through sustainable asset
                procurement.{" "}
              </p>
              <p className="mt-4">
                Consultants primarily serve in these roles: Business
                Development, Account Management and Trading.
              </p>
            </div>
          </div>
          <div className="row">
            {positions.slice(0, 12).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onClickPosition(item.id)}
                  className="col-sm-6 col-lg-4 mt-2 mb-2 cursor-pointer"
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className="career-position"
                  >
                    {item.name}
                  </div>
                </div>
              )
            })}
          </div>
          {positions?.length > 12 ? (
            <div className="row">
              <div className="col-md-12">
                {/* <p className="text-center mt-4"><a target="_blank" href="mailto:hr@salvex.com?subject=New Candidate" className="btn btn-primary btn-lg rounded-pill pl-4 pr-4">Apply Now</a></p> */}
                <p className="text-center mt-4">
                  <a
                    href="/all-open-positions"
                    className="btn btn-primary btn-lg rounded-pill pl-4 pr-4"
                  >
                    See More
                  </a>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </Layout>
  )
}
export default CareersPage
